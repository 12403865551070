"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DigestContents = void 0;
exports.DigestContents = [
    {
        week: 5,
        name: "1. 五感のリープ",
        thumbnail: `${process.env.REACT_APP_MICROCMS_IMAGE_PATH}9a7ab956deb34461838c5558468f4b77/leap5.webp`,
        title: "生後1ヶ月(5週)頃『五感のリープ』で感覚が目覚める！",
    },
    {
        week: 8,
        name: "2. パターンのリープ",
        thumbnail: `${process.env.REACT_APP_MICROCMS_IMAGE_PATH}2a77397877cf42458574222fcfc33857/leap8.webp`,
        title: "生後1ヶ月(8週)頃『パターンのリープ』で世界の規則性を発見！",
    },
    {
        week: 12,
        name: "3. 推移のリープ",
        thumbnail: `${process.env.REACT_APP_MICROCMS_IMAGE_PATH}3379a2438a8c4d87867fb95e210c8c8e/leap12.webp`,
        title: "生後2ヶ月(12週)頃『推移のリープ』で世界を新たな目で見る！",
    },
    {
        week: 19,
        name: "4. 出来事のリープ",
        thumbnail: `${process.env.REACT_APP_MICROCMS_IMAGE_PATH}cfebaafd16724143998a96c8c12f3740/leap19.webp`,
        title: "生後4ヶ月(19週)頃『出来事のリープ』で世界が複雑に感じられる！",
    },
    {
        week: 26,
        name: "5. 関係のリープ",
        thumbnail: `${process.env.REACT_APP_MICROCMS_IMAGE_PATH}3fadebf2cc39429a9ad299b4af3f1755/leap26.webp`,
        title: "生後5ヶ月(26週)頃『関係のリープ』で世界のつながりを発見！",
    },
    {
        week: 37,
        name: "6. 分類のリープ",
        thumbnail: `${process.env.REACT_APP_MICROCMS_IMAGE_PATH}7d01f6254b1f4ec8b4c77d01f7239503/leap37.webp`,
        title: "生後8ヶ月(37週)頃『分類のリープ』で物事を見分ける力が芽生える！",
    },
    {
        week: 46,
        name: "7. 順序のリープ",
        thumbnail: `${process.env.REACT_APP_MICROCMS_IMAGE_PATH}d3e5c01b09c14c00b3793d8781b6d01c/leap46.webp`,
        title: "生後10ヶ月(46週)頃『順序のリープ』で物事の流れを理解！",
    },
    {
        week: 55,
        name: "8. 工程のリープ",
        thumbnail: `${process.env.REACT_APP_MICROCMS_IMAGE_PATH}2ba1410058434c13989263ae51396082/leap55.webp`,
        title: "生後1歳0ヶ月(55週)頃『工程のリープ』で一連の作業を理解！",
    },
    {
        week: 64,
        name: "9. 原則のリープ",
        thumbnail: `${process.env.REACT_APP_MICROCMS_IMAGE_PATH}c2505561eec745cb890f53e407b9a855/leap64.webp`,
        title: "生後1歳2ヶ月(64週)頃『原則のリープ』でルールや原則を理解！",
    },
    {
        week: 75,
        name: "10. 体系のリープ",
        thumbnail: `${process.env.REACT_APP_MICROCMS_IMAGE_PATH}61965f3f1d904195a40267d63ff6e5b5/leap75.webp`,
        title: "生後1歳5ヶ月(75週)頃『体系のリープ』で自我が芽生え、適応力が向上！",
    },
];
